<template>
  <div>
    <validation-observer ref="simpleRules">
      <button class="btn btn-gradient-primary mb-1">
        {{ $t('saveData') }}
      </button>
      <b-row>
        <b-col md="8">

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                {{ $t('personalInfo') }}
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ชื่อ - สกุล <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Product Name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-product-id"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      อีเมล์ <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <b-form-input
                        id="h-product-id"
                        v-model="email"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      เบอร์โทรศัพท์ <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Phone Number"
                      rules="required"
                    >
                      <b-form-input
                        id="h-phone-number"
                        v-model="phoneNumber1"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-phone-number2"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      เบอร์โทรศัพท์(2) <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Phone"
                      rules="required"
                    >
                      <b-form-input
                        id="h-phone-number2"
                        v-model="phoneNumber2"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-different"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      Different <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Different"
                      rules="required"
                    >
                      <b-form-input
                        id="h-different"
                        v-model="different"
                        :state="errors.length > 0 ? false : null"
                        type="text"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-invite"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสผู้แนะนำ <span class="text-danger">*</span> :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Invite Code"
                      rules="required"
                    >
                      <v-select
                        id="h-invite"
                        v-model="inviteCode"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :state="errors.length > 0 ? false : null"
                        label="name"
                        :options="items"
                        class="w-100 mr-1"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                ข้อมูลการเข้าใช้งาน
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสลูกค้า <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Product Name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="login-password"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      รหัสผ่าน
                      <span class="text-danger">*</span>
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          class="form-control-merge"
                          :type="passwordFieldType"
                          name="login-password"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="passwordToggleIcon"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>

          <div class="card">
            <div
              class="header-cardx p-2 d-flex justify-content-between align-items-center"
            >
              <div>
                <h3 class="mb-0">
                  ที่อยู่ของฉัน
                </h3>
                <small
                  class="text-muted"
                >ตั้งค่าที่อยู่สำหรับการจัดส่งและที่อยู่สำหรับการนัดรับสินค้า</small>
              </div>

              <button
                class="btn btn-gradient-primary"
                @click="showAddAddr()"
              >
                <i class="far fa-plus" /> เพิ่มที่อยู่
              </button>
            </div>

            <div
              v-for="i in 2"
              :key="i"
              class="view"
            >
              <div class="icon">
                <i class="fas fa-map-marker-alt fa-2x text-success" />
              </div>
              <div class="content">
                <div class="grider">
                  <div class="label">
                    ชื่อ-นามสกุล
                  </div>
                  <div class="name">
                    ลมโชย พัดเพลิน
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    หมายเลขโทรศัพท์
                  </div>
                  <div class="phone">
                    081-234-5678
                  </div>
                </div>
                <div class="grider">
                  <div class="label">
                    ที่อยู่
                  </div>
                  <div class="detail">
                    79/189 หมู่บ้านศุภาลัยวิลล์ ซอยจามจุรีย์ 1/2 <br>
                    จังหวัดนนทบุรี <br>
                    อำเภอบางใหญ่ 11140
                  </div>
                </div>
              </div>
              <div class="operations text-r">
                <div class="edit">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                  >
                    <span> แก้ไข </span>
                  </button>
                </div>
                <div class="delete">
                  <button
                    type="button"
                    class="anan-button anan-button--link anan-button--normal"
                  >
                    <span> ลบ </span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                กลุ่มผู้ใช้งาน
              </h3>
            </div>

            <div class="p-2">
              <b-form-group
                label-for="h-catagory-name"
                label-cols-md="3"
              >
                <template v-slot:label>
                  กลุ่มผู้ใช้งาน <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Catagory"
                  rules="required"
                >
                  <div class="d-flex align-items-center">
                    <v-select
                      id="h-catagory-name"
                      v-model="category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :state="errors.length > 0 ? false : null"
                      label="name"
                      :options="items"
                      class="w-100 mr-1"
                    />
                    <i
                      class="far fa-edit text-primary cursor-pointer"
                      @click="showModal"
                    />
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div class="row">
                <div class="col-md-6">
                  <b-table
                    responsive="sm"
                    :fields="fieldsCar"
                    :items="itemsCar"
                    class="mb-2"
                  />
                </div>
                <div class="col-md-6">
                  <b-table
                    responsive="sm"
                    :fields="fieldsShip"
                    :items="itemsShip"
                  />
                </div>
              </div>

            </div>
          </div>
        </b-col>

        <b-col md="4">
          <div
            class="card"
            style="height: 200px;"
          >
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                รายละเอียดโมดูล
              </h3>
            </div>

            <div class="p-2">
              <b-form-group
                label-for="h-catagory-name"
                label-cols-md="3"
              >
                <template v-slot:label>
                  สิทธิ์การใช้งาน <span class="text-danger">*</span>
                </template>
                <validation-provider
                  #default="{ errors }"
                  name="Catagory"
                  rules="required"
                >
                  <div class="d-flex align-items-center">
                    <v-select
                      id="h-catagory-name"
                      v-model="category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :state="errors.length > 0 ? false : null"
                      label="name"
                      :options="items"
                      class="w-100 mr-1"
                    />
                    <i
                      class="far fa-edit text-primary cursor-pointer"
                      @click="showModal"
                    />
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div>
                <div
                  v-for="item in role"
                  :key="item.name"
                  class="d-flex justify-content-between mb-1 mt-2 align-items-center border-bottom"
                >
                  <div>
                    <h5 class="mb-0 font-weight-bolder">
                      {{ item.name }}
                    </h5>
                  </div>
                  <div>
                    <i
                      v-if="item.status"
                      class="fas fa-check-circle text-success"
                    />

                    <i
                      v-else
                      class="fas fa-times-circle text-danger"
                    />

                  </div>
                </div>
              </div>

            </div>
          </div>
        </b-col>
      </b-row>
    </validation-observer>

    <b-modal
      id="add-addr"
      title="เพิ่มที่อยู่ใหม่"
      ok-title="บันทึก"
      cancel-title="ยกเลิก"
      @ok.prevent="addAddr()"
    >
      <validation-observer ref="addRules">
        <b-form-group
          label-for="h-name"
          label="ชื่อ-นามสกุล"
        >
          <validation-provider
            #default="{ errors }"
            name="Full Name"
            rules="required"
          >
            <b-form-input
              id="h-name"
              v-model="fullName"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-phone-number"
          label="หมายเลขโทรศัพท์"
        >
          <validation-provider
            #default="{ errors }"
            name="Phone Number"
            rules="required"
          >
            <b-form-input
              id="h-phone-number"
              v-model="phoneNumber"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-address"
          label="ที่อยู่/เลขที่"
        >
          <validation-provider
            #default="{ errors }"
            name="Address"
            rules="required"
          >
            <b-form-textarea
              id="h-address"
              v-model="address"
              row="2"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-province-name"
          label="จังหวัด"
        >
          <validation-provider
            #default="{ errors }"
            name="Province"
            rules="required"
          >
            <b-form-input
              id="h-province-name"
              v-model="province"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-district-name"
          label="อำเภอ/เขต"
        >
          <validation-provider
            #default="{ errors }"
            name="District"
            rules="required"
          >
            <b-form-input
              id="h-district-name"
              v-model="district"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-sub-district-name"
          label="ตำบล/แขวง"
        >
          <validation-provider
            #default="{ errors }"
            name="Sub District"
            rules="required"
          >
            <b-form-input
              id="h-sub-district-name"
              v-model="subDistrict"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group
          label-for="h-postal-code"
          label="รหัสไปรษณีย์"
        >
          <validation-provider
            #default="{ errors }"
            name="Postal Code"
            rules="required"
          >
            <b-form-input
              id="h-postal-code"
              v-model="postalCode"
              :state="errors.length > 0 ? false : null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BModal,
  BInputGroup,
  BInputGroupAppend,
  BTable,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import vSelect from 'vue-select'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    vSelect,
    BModal,
    BInputGroup,
    BInputGroupAppend,
    BTable,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      email,
      name: '',
      category: '',
      code: '',
      details: '',
      selectedFiles: [],
      uploadedImages: [],
      fieldsCar: [
        { key: 'car', label: 'รถยนต์', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      fieldsShip: [
        { key: 'ship', label: 'เรือ', thStyle: { width: '50%' } },
        { key: 'kg', label: 'กิโล.', thStyle: { width: '25%' } },
        { key: 'queue', label: 'คิว.', thStyle: { width: '25%' } },
      ],
      itemsCar: [
        { car: 'รถยนต์ 4 ล้อ', kg: '1,000', queue: '1' },
        { car: 'รถยนต์ 6 ล้อ', kg: '1,500', queue: '2' },
        { car: 'รถยนต์ 10 ล้อ', kg: '2,000', queue: '3' },
      ],
      itemsShip: [
        { ship: 'เรือ 4 ล้อ', kg: '1,000', queue: '1' },
        { ship: 'เรือ 6 ล้อ', kg: '1,500', queue: '2' },
        { ship: 'เรือ 10 ล้อ', kg: '2,000', queue: '3' },
      ],
      role: [
        { name: 'ผู้ดูแล - รายการสั่งซื้อ', status: true },
        { name: 'ผู้ดูแล - ผู้ใช้งาน', status: false },
        { name: 'ผู้ดูแล - สิทธิ์การเข้าใช้งาน', status: false },
        { name: 'ผู้ดูแล - กลุ่มผู้ใช้งาน', status: false },
        { name: 'ผู้ดูแล - รหัสพัสดุ', status: false },
        { name: 'ผู้ดูแล - เติมเงินเข้าระบบ', status: false },
        { name: 'ผู้ดูแล - ถอนเงินออกจากระบบ', status: false },
        { name: 'ผู้ดูแล - สมุดบัญชี', status: false },
        { name: 'ผู้ดูแล - ใบเสร็จ', status: false },
        { name: 'ผู้ดูแล - ฝากจ่ายเถาเป่า', status: false },
        { name: 'ผู้ดูแล - ร้านค้าคืนเงิน', status: false },
        { name: 'ผู้ดูแล - เติมอาลีเพย์', status: false },
        { name: 'ผู้ดูแล - โอนเงินไปจีน', status: false },
        { name: 'ผู้ดูแล - สมุดเงินหยวน', status: false },
        { name: 'ผู้ดูแล - แลกหยวนเป็นบาท', status: false },
        { name: 'ผู้ดูแล - ชำระค่านำเข้า', status: false },
        { name: 'ผู้ดูแล - รายการส่งออกสินค้า', status: false },
        { name: 'ผู้ดูแล - สร้างใบชำระให้ลูกค้า', status: false },
        { name: 'ผู้ดูแล - แจ้งสินค้าไม่ได้', status: false },
        { name: 'ผู้ดูแล - สินค้าไม่มีเจ้าของ', status: false },
        { name: 'ผู้ดูแล - ล็อตสินค้า', status: false },
        { name: 'ผู้ดูแล - รับสินค้าเข้าไทย', status: false },
        { name: 'ผู้ดูแล - ข้อมูลโกดัง', status: false },
        { name: 'ผู้ดูแล - รายการแต้ม', status: false },
        { name: 'ผู้ดูแล - แลกแต้ม', status: false },
        { name: 'ผู้ดูแล - รายการสินค้า', status: false },
        { name: 'ผู้ดูแล - สต๊อคคงเหลือ', status: false },
        { name: 'ผู้ดูแล - ประเภทสินค้า', status: false },
        { name: 'ผู้ดูแล - ประเภทการจัดส่ง', status: false },
        { name: 'ผู้ดูแล - บัญชีธนาคาร', status: false },
        { name: 'ผู้ดูแล - ทั่วไป', status: false },
        { name: 'ผู้ดูแล - โปรโมชั่น', status: false },
        { name: 'ผู้ดูแล - ส่งของไปจีน', status: false },
        { name: 'ผู้ดูแล - ประกาศ', status: false },
        { name: 'ผู้ดูแล - รายงาน', status: false },
        { name: 'ผู้ดูแล - ส่วนลดล็อตสินค้า', status: false },
        { name: 'ผู้ดูแล - ประเภทการจัดส่ง(รถ/เรือ)', status: false },
        { name: 'ผู้ดูแล - คำถามที่พบบ่อย', status: false },
        { name: 'ผู้ดูแล - คูปอง', status: false },
        { name: 'ผู้ดูแล - รวมรหัสพัสดุ', status: false },
        { name: 'ผู้ดูแล - แก้ไขเรทรายการสั่งซื้อ', status: false },
        { name: 'ผู้ดูแล - ปลดล็อคถอนเงิน', status: false },
        { name: 'ผู้ดูแล - ระบบ QC', status: false },
        { name: 'ผู้ดูแล - ปิดงานโกดัง', status: false },
        { name: 'ผู้ดูแล - บทความ', status: false },
        { name: 'ผู้ดูแล - พนักงาน', status: false },
        { name: 'สมาชิก - ผู้ใช้งาน', status: true },
        { name: 'สมาชิก - รหัสพัสดุ', status: true },
        { name: 'รายการสั่งซื้อ', status: true },
        { name: 'เติมเงิน', status: true },
        { name: 'ชำระค่านำเข้า', status: true },
        { name: 'สมุดบัญชี', status: true },
        { name: 'รหัสพัสดุ', status: true },
        { name: 'อาลีเพย์', status: true },
        { name: 'โอนเงินไปจีน', status: true },
        { name: 'แจ้งไม่ได้รับสินค้า', status: true },
        { name: 'ฝากจ่ายเถาเป่า', status: true },
        { name: 'ร้านค้าคืนเงิน', status: true },
        { name: 'สมุดบัญชีเงินหยวน', status: true },
        { name: 'แต้ม', status: true },
        { name: 'สินค้าไม่มีเจ้าของ', status: true },
        { name: 'ANAN MALL', status: true },
      ],
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {},
  methods: {
    addAddr() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          this.$bvModal.hide('add-addr')
        }
      })
    },
    showAddAddr() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
